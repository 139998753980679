.App {
  background-color: #212121;
  min-height: 100vh;
  color: white;
}

.App-link {
  color: #61dafb;
}

.dropdownButton {
  border: none;
  background: none;
  outline: none !important;
  font-family:roboto, sans-serif;
  color: white !important;
  font-size:15px;
  font-weight:700;
  float: left;
}

.spinnerStyle {
  left: 0;
  line-height: 200px;
  margin: auto;
  margin-top: -100px;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}

/* @keyframes blink { 
  50% { border-color: #ff0000; } 
}

.errorBorder {
  border: 5px solid;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  animation: blink .5s step-end infinite alternate;
} */

.gameWidgetContainer {
  display:  flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.MuiInputBase-root {
  color: white !important;
}

/* .MuiPaper-elevation2 {
  height: 833px !important;
} */

.Component-horizontalScrollContainer-48 {
  height: 713px
}

.lineupStyle {
  display: inline-block;
  vertical-align:top;
  width: 50% !important;
}

.MuiTable-root {
  color: white !important;
}

.injuryPlayerName {
  cursor: pointer;
}

.injuryPlayerName:hover {
  cursor: pointer;
  color:#E4AE3A;
  font-weight: bolder;
}

@media (max-width: 640px){
  .lineupStyle {
    display: inline-block;
    vertical-align:top;
    width: 100% !important;
  }
}


@media (max-width: 767px){
  .gameWidgetContainer {
    width: 340px !important;
  }

  .gameWidgetBorder {
    margin:10px 0px 0px 0px !important; 
  }

  .goodLabelStyle {
    margin:0px 0px 10px 0px !important; 
  }

  .badLabelStyle {
    margin:0px 0px 10px 0px !important; 
  }
}

.gameWidgetBorder {
  width:340px; 
  margin:10px 10px 0px 10px; 
  color:#dedede; 
  text-align: center;
  display: flex;
  border-top: 1px solid #484646;
  border-left: 1px solid #484646;
  border-right: 1px solid #484646;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.homeCenterInfo {
  font-size:16px;
  text-align:center;
  font-weight:bold;
  color: #dedede !important;
  text-decoration: none !important;
  font-family:Roboto, sans-serif;
  width:100px;
}

.homeCenterInfo:hover {
  font-size:16px;
  text-align:center;
  font-weight:bold;
  color: #E4AE3A !important;
  text-decoration: none !important;
  font-family:Roboto, sans-serif;
  width:100px;
}

#periodTable{
  display: inline-block !important;
  background: #333333 !important;
  border-radius: unset !important;
  color: #fff !important;
  min-width: unset !important;
  margin-top: 10px !important;
  /* margin-right: 20px !important;
  max-width: 300px !important; */
  vertical-align: top !important;
}

#league_table{
  display: inline-block !important;
  background: #333333 !important;
  border-radius: unset !important;
  color: #fff !important;
  min-width: unset !important;
  margin-top: 10px !important;
  /* max-width: 500px !important; */
  vertical-align: top !important;
}

@keyframes blinkLabel { 
  50% { background-color: #f4433661; } 
}

.PossessionStyle {
  border: 2px solid #E4AE3A
}

.noPossessionStyle {
  border: 2px solid #333
}

.goodLabelStyle {
  border: 1px solid #484646;
  margin:0px 10px 10px 10px; 
  width: 340px; 
  color: black; 
  text-align: center; 
  display: flex;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #1eb83f8c;
}

.badLabelStyle {
  border: 1px solid #484646;
  margin:0px 10px 10px 10px; 
  width: 340px; 
  color: black; 
  text-align: center; 
  display: flex;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  animation: blinkLabel 2s linear infinite;
}

.calendarButton {
  font-size: 40px !important;
  height: 54px !important;
  width: 54px !important;
  background-color: #3f51b5 !important;
}

.okLabel {
  font-size: 12px !important;
  height: 20px !important;
  width: 20px !important;
  margin: 4px 0px 4px 4px;
  background-color: #3f51b5 !important;
  font-weight: 500 !important;
}

.nokayLabel {
  font-size: 12px !important;
  height: 20px !important;
  width: 20px !important;
  margin: 4px 0px 4px 4px;
  background-color: #5f5f5f !important;
  font-weight: 500 !important;
}

.emptyLabel {
  font-size: 12px !important;
  height: 20px !important;
  width: 20px !important;
  margin: 4px 0px 4px 4px;
  background-color: #7d7d7d !important;
  font-weight: 500 !important;
  /* color: red !important; */
}

.homeTabStyle {
  background: #212121;
  border-bottom: none !important;
}

.homeTabStyle .nav-link {
  height: 35px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 30px;
  color: #626262 !important;
  outline: none !important;
  display:inline-block;
  font-family:roboto, sans-serif;
  font-size:23px;
  font-weight:500;
}

.homeTabStyle .nav-link:hover {
  height: 35px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 30px;
  color: #E4AE3A !important;
  border-color: transparent !important;
  outline: none !important;
  display:inline-block;
  font-family:roboto, sans-serif;
  font-size:23px;
  font-weight:500;
}

.homeTabStyle .nav-link.active {
  height: 35px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 30px;
  display:inline-block;
  background-color: #212121 !important;
  font-family:roboto, sans-serif;
  color:#E4AE3A !important;
  font-size:23px;
  font-weight:500;
  border-bottom: 2px solid #E4AE3A !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  outline: none !important;
}

.cardStyle {
  border: none !important;
  background-color: #212121 !important;
}

.card-header {
  background-color: #4e4e4e !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
