#okta-sign-in.auth-container .button-primary {
  /* -- Buttons' Colors -- */
  color: #fff;
  background-color: #E4AE3A;
  background: -o-linear-gradient(top, #E4AE3A 0%, #E4AE3A 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #E4AE3A 0%, #E4AE3A 100%);
  /* IE10+ */
  background: -webkit-gradient(linear, left top, left bottom, from(#E4AE3A), to(#E4AE3A));
  background: -webkit-linear-gradient(#E4AE3A, #E4AE3A);
  background: linear-gradient(#E4AE3A, #E4AE3A);
  border-color: #E4AE3A;
  border-bottom-color: #E4AE3A;
}

#okta-sign-in.auth-container .button-success {
  /* -- Buttons' Colors -- */
  color: #fff;
  background-color: #E4AE3A;
  background: -o-linear-gradient(top, #E4AE3A 0%, #E4AE3A 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #E4AE3A 0%, #E4AE3A 100%);
  /* IE10+ */
  background: -webkit-gradient(linear, left top, left bottom, from(#E4AE3A), to(#E4AE3A));
  background: -webkit-linear-gradient(#E4AE3A, #E4AE3A);
  background: linear-gradient(#E4AE3A, #E4AE3A);
  border-color: #E4AE3A;
  border-bottom-color: #E4AE3A;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

#okta-sign-in.auth-container .button-success:hover, #okta-sign-in.auth-container .button-success:focus, #okta-sign-in.auth-container .button-success:active, #okta-sign-in.auth-container .button-success:focus {
  /* -- Buttons' Colors -- */
  background-color: #E4AE3A;
  background: -o-linear-gradient(top, #E4AE3A 0%, #E4AE3A 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #E4AE3A 0%, #E4AE3A 100%);
  /* IE10+ */
  background-image: -webkit-gradient(linear, left top, left bottom, from(#E4AE3A), to(#E4AE3A));
  background-image: -webkit-linear-gradient(#E4AE3A, #E4AE3A);
  background-image: linear-gradient(#E4AE3A, #E4AE3A);
  border-color: #E4AE3A;
  border-bottom-color: #E4AE3A;
  text-decoration: none;
  cursor: pointer;
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
  /* -- Buttons' Colors -- */
  color: #fff;
  background-color: #E4AE3A;
  border-color: #E4AE3A;
  background-image: none;
  box-shadow: none;
}

#okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: none;
}

#okta-sign-in .focused-input,#okta-sign-in .link.help:focus{
  box-shadow:0 0 8px rgba(5, 5, 5, 0.507)};
